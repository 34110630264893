import {isDevMode} from '@angular/core';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromPixelSearchMatchReducer from '../core/reducers/pixelSearchMatch.reducer';
import * as fromPixelSearchReducer from '../core/reducers/pixelSearch.reducer';
import * as fromEProfileReducer from '../core/reducers/eProfile.reducer';
import * as fromCProfileReducer from '../core/reducers/cProfile.reducer';
import * as fromCommonReducer from '../core/reducers/common.reducer';
import * as fromSearchReducer from '../core/reducers/search.reducer';
import * as fromSubscriptionReducer from '../core/reducers/subscription.reducer';
import * as fromJobOfferReducer from '../core/reducers/jobOffer.reducer';
import * as fromPropositionReducer from '../core/reducers/proposition.reducer';
import * as fromSubscriberSearchReducer from '../core/reducers/subscriberSearch.reducer';
import * as fromEducationReducer from '../core/reducers/education.reducer';

export interface State {
  pixelSearchMatch: fromPixelSearchMatchReducer.State,
  pixelSearch: fromPixelSearchReducer.State
  eProfile: fromEProfileReducer.State,
  cProfile: fromCProfileReducer.State,
  common: fromCommonReducer.State,
  search: fromSearchReducer.State,
  subscription: fromSubscriptionReducer.State,
  jobOffer: fromJobOfferReducer.State,
  proposition: fromPropositionReducer.State,
  subscriberSearch: fromSubscriberSearchReducer.State,
  education: fromEducationReducer.State,
}

export const reducers: ActionReducerMap<State> = {
  pixelSearchMatch: fromPixelSearchMatchReducer.reducer,
  pixelSearch: fromPixelSearchReducer.reducer,
  eProfile: fromEProfileReducer.reducer,
  cProfile: fromCProfileReducer.reducer,
  common: fromCommonReducer.reducer,
  search: fromSearchReducer.reducer,
  subscription: fromSubscriptionReducer.reducer,
  jobOffer: fromJobOfferReducer.reducer,
  proposition: fromPropositionReducer.reducer,
  subscriberSearch: fromSubscriberSearchReducer.reducer,
  education: fromEducationReducer.reducer,
};

export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
