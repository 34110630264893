<ng-container *ngIf="business$ | async as business">
  <ng-container *ngIf="eProfile$ | async as eProfile">

    <div class="d-flex flex-column gap-5 h-100 pb-4" style="padding-top: 40px;">
      <div class="d-none d-lg-flex align-items-center gap-3 color-secondary px-3" *ngIf="eProfile.business === 1">
        <div class="oval-2"></div>
        <fa-icon [icon]="fa.faMagnifyingGlass" size="2x"></fa-icon>
        <h1>Candidats</h1>
      </div>
      <div class="d-flex align-items-center gap-3 color-secondary px-3" *ngIf="eProfile.business === 2">
        <div class="title-fifty">
          <div class="d-flex align-items-center gap-3 color-secondary mb-3 ">
            <div class="oval-2"></div>
            <fa-icon [icon]="fa.faUsersViewfinder" size="2x"></fa-icon>
            <h1 class="color-tertiary mb-0">Matchs</h1>
          </div>
        </div>
        <div class="title-pixel">
          <div class="d-flex align-items-center gap-3 mb-3 color-tertiary">
            <div class="bg-primary p-2" style="border-radius: 6px">
              <fa-icon [icon]="fa.faUsersViewfinder" size="lg" [fixedWidth]="true"></fa-icon>
            </div>
            <h1 class="mb-0">Matchs</h1>
          </div>
        </div>
      </div>
      <div class="dropdown mx-3" *ngIf="eProfile.business === 3">
        <button class="btn dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center gap-2">
              <div class="color-tertiary font-size-18 fw-bold">
                <ng-container [ngSwitch]="business">
                  <ng-container *ngSwitchCase="1">
                    Candidats
                  </ng-container>
                  <ng-container *ngSwitchCase="2">
                    Matchs
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="color-tertiary">
              <fa-icon [icon]="fa.faCaretDown"></fa-icon>
            </div>
          </div>
        </button>

        <ul class="dropdown-menu">
          <div class="fw-bold">Choisir un service</div>
          <hr class="my-2">
          <li class="dropdown-item d-flex flex-column gap-1" [ngClass]="{'selected': business === 1}"
              (click)="changeBusiness(1)">
            <div class="d-flex gap-3 align-content-center">
              <div class="align-self-center">
                <img src="assets/images/small-logo.png" style="width: 40px">
              </div>
                <div class="d-flex gap-2 flex-column">
                  <div class="color-tertiary">
                    Candidats
                  </div>
                  <div class="text-muted font-size-12">
                    Proposé par fifty Talents
                  </div>
                </div>
            </div>
          </li>
          <li class="dropdown-item d-flex flex-column gap-1" [ngClass]="{'selected': business === 2}"
              (click)="changeBusiness(2)">

            <div class="d-flex gap-3 align-content-center">
              <div class="align-self-center">
                <img src="assets/images/small-logo-pixel.svg" style="height: 40px; min-width: 40px">
              </div>
              <div class="d-flex gap-2 flex-column">
                <div class="color-tertiary">
                  Matchs
                </div>
                <div class="text-muted font-size-12">
                  Proposé par Pixel
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div *ngIf="business === 1" class="overflow-auto scrollbar px-3">
        <ng-container *ngIf="subscriberSearches$ | async as subscriberSearches">
          <div class="d-flex flex-column gap-3 mb-3" *ngIf="formIsNotEmpty() || subscriberSearches.length">

            <ng-container *ngIf="subscriberSearches.length">
              <div class="fw-bold">Recherches enregistrées</div>
              <form [formGroup]="formSearch" class="d-flex flex-column gap-1">
                <ng-container  *ngFor="let subscriberSearch of subscriberSearches">
                  <div class="d-flex justify-content-between gap-2">
                    <div class="form-check form-check-inline radio " (click)="selectSearch(subscriberSearch)">
                      <input class="form-check-input" type="radio" [id]="'inlineRadio1-'+ subscriberSearch.id" [value]="subscriberSearch.id" formControlName="subscriberSearchId">
                      <label class="form-check-label " [title]="subscriberSearch.name" [for]="'inlineRadio1-'+ subscriberSearch.id">{{subscriberSearch.name}}</label>
                    </div>
                    <div class="edit" (click)="saveSearch(subscriberSearch)">
                      <fa-icon [icon]="fa.faPen"></fa-icon>
                    </div>
                  </div>
                </ng-container>
              </form>

              <hr class="my-0">
            </ng-container>

            <ng-container *ngIf="formIsNotEmpty()">
              <div class="d-flex justify-content-between">
                <div class="fw-bold">
                  Filtres actifs
                </div>
                <div class="fw-bold pointer" *ngIf="form.touched" (click)="reset()">
                  <fa-icon [icon]="fa.faCircleXmark" class="delete"></fa-icon>
                  Réinitialiser
                </div>
              </div>

              <div class="d-flex flex-wrap gap-2">
                <ng-container *ngIf="form.controls.content.value?.length !== 0">
                  <div *ngFor="let content of form.controls.content.value" class="tag tag-secondary selected pointer" (click)="formContentComponent.removeContent(content)">
                    {{content}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>
                <ng-container *ngIf="form.controls.jobs.value?.length !== 0">
                  <div *ngFor="let job of form.controls.jobs.value" class="tag tag-secondary selected pointer" (click)="formJobsComponent.removeJob(job)">
                    {{job.title}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.skills.value?.length !== 0">
                  <ng-container *ngFor="let skill of form.controls.skills.value">
                    <div class="tag tag-secondary selected pointer" (click)="formSkillComponent.removeSkill(skill)">
                      {{skill.title}} <span *ngIf="skill.is_favorite">(requis) </span><fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                    </div>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="!(form.controls.experience_min.value === 0 && form.controls.experience_max.value === 8)">
                  <div class="tag tag-secondary selected pointer" (click)="resetExperiences()">
                    {{form.controls.experience_min.value}} à {{form.controls.experience_max.value}} ans d'expériences <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.remote.value?.length">
                  <div class="tag tag-secondary selected pointer" *ngFor="let remote of form.controls.remote.value" (click)="formRemoteComponent.remove(remote)">
                    <ng-container [ngSwitch]="remote">
                      <ng-container *ngSwitchCase="0">
                        Au Bureau
                      </ng-container>
                      <ng-container *ngSwitchCase="1">
                        Full Remote
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        Hybride ({{form.controls.remote_min.value}} à {{form.controls.remote_max.value}} jours)
                      </ng-container>
                    </ng-container>
                    <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.city.value">
                  <div class="tag tag-secondary selected pointer" (click)="formAddressComponent.remove()">
                    {{form.controls.city.value}} ({{form.controls.perimeter.value}} kms) <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.salary_min.value && form.controls.salary_max.value">
                  <div class="tag tag-secondary selected pointer" (click)="resetSalary()">
                    {{form.controls.salary_min.value}} - {{form.controls.salary_max.value}} k€/an <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.schools.value?.length !== 0">
                  <div  *ngFor="let school of form.controls.schools.value" class="tag tag-secondary selected pointer" (click)="formSchoolComponent.removeContent(school)">
                    {{school}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>

                <ng-container *ngIf="form.controls.level.value">
                  <div class="tag tag-secondary selected pointer" (click)="resetLevel()">
                    {{form.controls.level.value}} <fa-icon [icon]="fa.faCircleXmark"></fa-icon>
                  </div>
                </ng-container>
              </div>
              <div class="align-self-center">
                <div class="color-secondary-1 text-decoration-underline pointer fw-bold" (click)="saveSearch()">
                  <ng-container *ngIf="currentSubscriberSearch$ | async as currentSubscriberSearch; else newSearch">
                    Enregistrer les nouveaux filtres
                  </ng-container>
                  <ng-template #newSearch>
                    Enregistrer la recherche
                  </ng-template>
                </div>
              </div>
              <hr class="my-0">
            </ng-container>
          </div>
        </ng-container>

        <form [formGroup]="form" (change)="submit()" class="overflow-auto scrollbar py-0">
          <div class="d-flex flex-column gap-3">
            <div>
              <label class="form-label">Mots-clés</label>
              <app-form-content #formContentComponent [formControl]="form.controls.content" placeholder="Rechercher" [displayPreview]="false"></app-form-content>
            </div>

            <div>
              <label class="form-label">Type de poste</label>
              <app-form-jobs #formJobsComponent [formControl]="form.controls.jobs" [displayPreview]="false"></app-form-jobs>
            </div>

            <div>
              <label class="form-label">Compétences requises</label>
              <app-form-skill #formSkillComponent [formControl]="form.controls.skills" [class]="'col-12'"
                              [skillsItems]="skillsItems"
                              [withLevel]="false" [displayPreview]="false"
                              [isFavorite]="true" [max]="5" [canAddSkill]="false"></app-form-skill>
            </div>

            <div>
              <label class="form-label">Compétences facultatives</label>
              <app-form-skill [formControl]="form.controls.skills" [class]="'col-12'"
                              [skillsItems]="skillsItems"
                              [withLevel]="false" [displayPreview]="false"
                              [isFavorite]="false" [max]="5" [canAddSkill]="false"></app-form-skill>
            </div>

            <div class="">
              <label class="form-label">Expérience</label>
              <app-form-year-experience #formYearExperienceComponent [form]="form" [min]="0" [max]="8" [ticksStep]="2"></app-form-year-experience>
            </div>

            <div>
              <label class="form-label">Localisation</label>
              <app-form-address #formAddressComponent [form]="form" type="city_perimeter" [displayResult]="true" [displayPreview]="false"></app-form-address>
            </div>

            <div>
              <label class="form-label">Mode de travail</label>
              <app-form-remote #formRemoteComponent [checkbox]="true" [form]="form" [displaySlider]="true" unity=""></app-form-remote>
            </div>

            <div *ngIf="subscriber.role === 'Manager'">
              <label class="form-label">Salaire brut annuel</label>
              <div class="d-flex flex-row gap-3">
                <div class="col flex-even">
                  <label class="col-form-label">MIN K€</label>
                  <input type="number" class="form-control" formControlName="salary_min" placeholder="35" min="30"
                         max="180"
                         [ngClass]="{'is-invalid' : (salaryMin.dirty || salaryMin.touched) && salaryMin.errors}">
                  <div *ngIf="salaryMin?.invalid && (salaryMin?.dirty || salaryMin?.touched)"
                       class="invalid-feedback">
                    <div *ngIf="salaryMin?.hasError('required')">
                      Ce champ est obligatoire
                    </div>
                    <div *ngIf="salaryMin?.hasError('atLarger')">
                      Ce champ doit être plus grand que {{ salaryMin.errors?.['atLarger'] }} K€
                    </div>
                    <div *ngIf="salaryMin?.hasError('atLess')">
                      Ce champ doit être plus petit que {{ salaryMin.errors?.['atLess'] }} K€
                    </div>
                    <div *ngIf="salaryMin?.errors?.['serverError']">
                      {{ salaryMin.errors?.['serverError'] }}
                    </div>
                  </div>
                </div>
                <div class="col flex-even">
                  <label class="col-form-label">MAX K€</label>
                  <input type="number" class="form-control" formControlName="salary_max" placeholder="40" min="40"
                         max="200"
                         [ngClass]="{'is-invalid' : (salaryMax.dirty || salaryMax.touched) && salaryMax.errors}">
                  <div *ngIf="salaryMax?.invalid && (salaryMax?.dirty || salaryMax?.touched)"
                       class="invalid-feedback">
                    <div *ngIf="salaryMax?.hasError('required')">
                      Ce champ est obligatoire
                    </div>
                    <div *ngIf="salaryMax?.hasError('atLarger')">
                      Ce champ doit être plus grand que {{ salaryMax.errors?.['atLarger'] }} K€
                    </div>
                    <div *ngIf="salaryMax?.hasError('atLess')">
                      Ce champ doit être plus petit que {{ salaryMax.errors?.['atLess'] }} K€
                    </div>
                    <div *ngIf="salaryMax?.errors?.['serverError']">
                      {{ salaryMax.errors?.['serverError'] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label class="form-label">Ecole</label>
              <app-form-school #formSchoolComponent [allSchools]="allSchools" [formControl]="form.controls.schools" [schoolSelected]="schoolSelected"></app-form-school>
            </div>

            <div>
              <label for="level" class="form-label">Niveau d'étude</label>
              <ng-select
                [items]="[{title: 'BAC'}, {title: 'BAC+1'}, {title: 'BAC+2'}, {title: 'BAC+3'}, {title: 'BAC+4'}, {title: 'BAC+5'}, {title: 'BAC+6'}, {title: 'BAC+7'}]"
                bindLabel="title"
                bindValue="title"
                id="level"
                appendTo="body"
                formControlName="level"
                [multiple]="false"
                [clearSearchOnAdd]="true"
                [clearable]="false"
                [closeOnSelect]="true"
                placeholder="Sélectionner">
              </ng-select>
            </div>
          </div>

        </form>

        <app-modal-search #modalSearchComponent></app-modal-search>
      </div>

      <div *ngIf="business === 2" class="overflow-auto px-3 pixel scrollbar">

        <ul class="nav nav-pills nav-flush d-flex flex-column gap-2">
          <li class="nav-item flex-fill">
            <a class="nav-link d-flex gap-2" routerLink="/search" [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
               [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}">
              <fa-icon [icon]="fa.faUser"></fa-icon>
              <div>Profils du jour</div>
            </a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link d-flex gap-2" routerLink="/search/history" [routerLinkActive]="'active'"
               [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'">
              <fa-icon [icon]="fa.faClockRotateLeft"></fa-icon>
              <div>Historique</div>
            </a>
          </li>
          <li class="nav-item flex-fill">
            <a class="nav-link d-flex gap-2" routerLink="/search/about"
               [attr.data-bs-dismiss]="!offcanvas ? '' : 'offcanvas'"
               [routerLinkActive]="'active'">
              <fa-icon [icon]="fa.faGear"></fa-icon>
              <div>Fonctionnement</div>
            </a>
          </li>
        </ul>

        <div class="card m-0 mt-5 d-none d-lg-block" style="background-color: var(--color-black-3);box-shadow: unset; border: 0"
             *ngIf="quota$ | async as quota">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center gap-3 ">
              <div class="flex-fill color-secondary-1 fw-bold font-size-18 text-center">
                Trouvez votre prochain profil tech
              </div>
              <div class="text-white py-2 px-2" style="border-radius: 30px; border: 1px solid rgba(255, 255, 255, 0.08); background-color: rgba(255, 255, 255, 0.04)">
                <fa-layers [fixedWidth]="true" size="xs">
                  <fa-icon [icon]="fa.faChevronDown" stackItemSize="1x" transform="up-4"></fa-icon>
                  <fa-icon [icon]="fa.faChevronDown" stackItemSize="1x" transform="down-2"></fa-icon>
                  <fa-icon [icon]="fa.faChevronDown" stackItemSize="1x" transform="down-8"></fa-icon>
                </fa-layers>
              </div>
              <div class="text-center">
                <div class=" fw-bold"><span class="text-white" style="font-size: 28px">{{ quota.today.max_contact - quota.today.contact }}</span>
                  <span style="color: #D0D6DD">/{{ quota.today.max_contact }} contact(s)</span>
                </div>
                <div class="font-size-12 color-secondary-1">A utiliser aujourd'hui</div>
              </div>
              <div class="text-center">
                <div class=" fw-bold"><span class="text-white" style="font-size: 28px">{{ quota.today.max_visit - quota.today.visit }}</span>
                  <span style="color: #D0D6DD">/{{ quota.today.max_visit }} vu(s)</span>
                </div>
                <div class="font-size-12 color-secondary-1">Disponible aujourd'hui</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
