<ng-select *ngIf="allSchools" #tmpSelectedSchool
           [(ngModel)]="schoolSelected"
           [style.height]="'41.5px'"
           [selectableGroup]="true"
           [clearSearchOnAdd]="false"
           [closeOnSelect]="false"
           [multiple]="true"
           [clearable]="false"
           [selectableGroupAsModel]="false"
           bindLabel="title"
           bindValue="title"
           groupBy="group"
           placeholder="Ecoles"
           (add)="search($event)"
           (clear)="removeContent($event)"
           (remove)="removeContent($event)"
           [searchable]="false"
           [items]="allSchools">
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-clear="clear">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [ngModel]="item$.selected"/>
      <label class="form-check-label">{{item.title}}</label>
    </div>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
    <span *ngIf="items.length > 0" class="fst-italic text-size-18" style="opacity: 0.6; color: #515151; padding-left: 3px; padding-top: 0.8px;">Ecoles</span>
  </ng-template>
</ng-select>