import {NgModule} from '@angular/core';
import {FormSchoolComponent} from "./form-school.component";
import {SharedModule} from "../../shared.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {CommonModule} from '@angular/common';
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    imports: [SharedModule, FontAwesomeModule, CommonModule, NgSelectModule],
  declarations: [FormSchoolComponent],
  exports: [FormSchoolComponent],
})
export class FormSchoolModule {}
