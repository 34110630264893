import {createReducer, on} from '@ngrx/store';
import * as EducationActions from '../actions/education.actions'

export const EducationFeatureKey = 'education';

export interface State {
  schools: string[],
  loading: boolean,
  error: any
}

export const initialState: State = {
  schools: [],
  loading: false,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(EducationActions.loadSchools, (state) => {
    return { ...state, loading: true, error: null };
  }),
  on(EducationActions.loadSchoolsSuccess, (state, { data }) => {
    return {
      ...state,
      schools: data,
      loading: false,
      error: null
    };
  }),
  on(EducationActions.loadSchoolsFailure, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
);