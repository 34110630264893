import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";
import {SubscriberSearchModel} from "../models/subscriberSearch.model";

const API_URL = environment.API_URL;
const PATH = '/subscriberSearch/'

@Injectable({
  providedIn: 'root'
})
export class SubscriberSearchService {

  constructor(private http: HttpClient) {
  }

  all() {
    return this.http.get<any>(API_URL + PATH)
  }

  store(subscriberSearch: SubscriberSearchModel) {
    return this.http.post<any>(API_URL + PATH, {
      name: subscriberSearch.name, content: subscriberSearch.content, experience_min: subscriberSearch.experience_min,
      experience_max: subscriberSearch.experience_max, remote: subscriberSearch.remote,
      remote_min: subscriberSearch.remote_min, remote_max: subscriberSearch.remote_max,
      salary_min: subscriberSearch.salary_min, salary_max: subscriberSearch.salary_max,
      city: subscriberSearch.city, city_perimeter: subscriberSearch.city_perimeter,
      country: subscriberSearch.country, schools: subscriberSearch.schools, zip_code: subscriberSearch.zip_code,
      level: subscriberSearch.level, skills: subscriberSearch.skills, jobs: subscriberSearch.jobs
    })
  }

  update(subscriberSearch: SubscriberSearchModel) {
    return this.http.patch<any>(API_URL + PATH + subscriberSearch.id, {
      name: subscriberSearch.name, content: subscriberSearch.content, experience_min: subscriberSearch.experience_min,
      experience_max: subscriberSearch.experience_max, remote: subscriberSearch.remote,
      remote_min: subscriberSearch.remote_min, remote_max: subscriberSearch.remote_max,
      salary_min: subscriberSearch.salary_min, salary_max: subscriberSearch.salary_max,
      city: subscriberSearch.city, city_perimeter: subscriberSearch.city_perimeter,
      country: subscriberSearch.country, schools: subscriberSearch.schools, zip_code: subscriberSearch.zip_code,
      level: subscriberSearch.level, skills: subscriberSearch.skills, jobs: subscriberSearch.jobs
    })
  }

  delete(subscriberSearch: SubscriberSearchModel) {
    return this.http.delete<any>(API_URL + PATH + subscriberSearch.id, )
  }
}
