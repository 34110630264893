import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {faCircleXmark} from "@fortawesome/pro-regular-svg-icons";
import {faSearch} from "@fortawesome/pro-light-svg-icons";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-form-school',
  templateUrl: './form-school.component.html',
  styleUrls: ['./form-school.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormSchoolComponent,
      multi: true
    }
  ]
})
export class FormSchoolComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  fa = {faCircleXmark, faSearch}

  @Input()
  formControl!: FormControl

  @Input()
  allSchools: any

  @Input()
  schoolSelected: any

  ngUnsubscribe = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any) {}
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  search($event: any) {
    let school = $event.title
    let tmpContent: any[] = this.formControl.value
    if (!tmpContent) {
      tmpContent = []
    }

    if (school) {
      this.formControl.patchValue([...tmpContent, school])
    }
    else {
      this.allSchools.forEach((element:any) => {
        if (element.group === $event.group) {
          this.formControl.patchValue([...tmpContent, element.title])
          tmpContent = this.formControl.value
        }
      });
    }
  }

  removeContent(content: any) {
    if (content.children !== undefined) {
      for (let index = 0; index < content.children.length; index++) {
        const element = content.children[index];
        this.removeContent(element);
      }
    }
    let tmpContent: any[] = [...this.formControl.value]; 
    let title: any;
    if (content.label != null) {
      title = content.label
    }
    else {
      title = content
    }
    if (title) {
      let index = tmpContent.findIndex((item: any) => item == title);
      if (index !== -1) {
        tmpContent.splice(index, 1)
        this.schoolSelected = this.schoolSelected.filter((i:any) => i !== title);
      }
    }

    this.formControl.patchValue(tmpContent)
    this.ref.markForCheck()
  }
}
